<template>
  <div id="printArea">
    <div class="row">
      <div class="col-md-10">
        <h4 class="text-uppercase">Bill Settlement Details</h4>
      </div>
      <div class="col-md-2 text-right d-print-none">
        <a
          href="javascript:void(0)"
          @click="
            $router.push(
              '/bills/settlements/' +
                settlement.id +
                '/edit?mode=edit&account_number=' +
                settlement.customer_account.account_number
            )
          "
          v-if="
            checkUsrPermissions(['BS_all', 'BS_edit']) &&
              settlement.status !== 'APPROVED' &&
              settlement.status !== 'COMPLETED'
          "
          class="btn btn-primary mr-4"
          ><i class="fa fa-pencil" aria-hidden="true"></i> Edit
        </a>

        <a href="javascript:void(0)" class="print-btn" @click="print()"
          ><i class="fa fa-print" aria-hidden="true"></i> Print</a
        >
      </div>
    </div>

    <CRow>
      <CCol sm="12">
        <CCard>
          <CRow>
            <CCol sm="12">
              <div class="table-responsive">
                <table class="table table-borderless appl-view-tbl">
                  <thead>
                    <tr>
                      <th colspan="6">Primary Information</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Full Name:</td>
                      <td>
                        {{
                          settlement.customer_account.salutation +
                            " " +
                            settlement.customer_account.first_name +
                            " " +
                            settlement.customer_account.last_name
                        }}
                      </td>
                      <td>Mobile No:</td>
                      <td>{{ settlement.customer_account.mobile_number }}</td>
                      <td>Account No</td>
                      <td>
                        {{ settlement.customer_account.account_number }}
                      </td>
                    </tr>

                    <tr>
                      <td>UID</td>
                      <td>{{ settlement.uid }}</td>
                      <td>Discount</td>
                      <td>
                        {{ settlement.discount_value
                        }}{{
                          settlement.discount_type === "percent" ? "%" : ""
                        }}
                      </td>
                      <td>Is EMI?</td>
                      <td>
                        {{ settlement.is_emi ? "Yes" : "No" }}
                      </td>
                    </tr>
                    <tr v-if="settlement.is_emi">
                      <td>Installment Period</td>
                      <td>
                        {{ settlement.installment_period }}
                      </td>
                      <td>No of Installments</td>
                      <td>
                        {{ settlement.number_of_installment }}
                      </td>
                      <td>Start Date</td>
                      <td>
                        {{
                          settlement.start_date
                            ? dateFormat(settlement.start_date)
                            : "N/A"
                        }}
                      </td>
                    </tr>

                    <tr>
                      <td>Total Amount</td>
                      <td>
                        {{ formatPrice(settlement.total_amount) }}
                      </td>
                      <td>Created At</td>
                      <td>{{ dateTimeFormat(settlement.created_at) }}</td>
                      <td>Proposed By</td>
                      <td>
                        {{ settlement.proposed_by }}
                      </td>
                    </tr>
                    <tr>
                      <td>Status</td>
                      <td>
                        <span
                          class="badge"
                          :class="
                            settlement.status === 'APPROVED'
                              ? 'badge-success'
                              : settlement.status === 'REJECTED'
                              ? 'badge-danger'
                              : 'badge-primary'
                          "
                          >{{ settlement.status }}</span
                        >
                      </td>
                      <td v-if="settlement.status === 'REJECTED'">
                        Reasons of Rejection
                      </td>
                      <td v-if="settlement.status === 'REJECTED'">
                        {{ settlement.rejected_reason }}
                      </td>
                    </tr>
                  </tbody>
                </table>

                <table class="table table-borderless appl-view-tbl">
                  <thead>
                    <tr>
                      <th colspan="6">Billing Details</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th>Bill No</th>
                      <th>Bill Month</th>
                      <th>Issue Date</th>
                      <th>Due Date</th>
                      <th>Total Amount</th>
                    </tr>
                    <tr v-for="bill in settlement.bills" :key="bill.id">
                      <td>{{ bill.bill_number }}</td>
                      <td>
                        {{ months[bill.bill_month] + ", " + bill.bill_year }}
                      </td>
                      <td>{{ dateFormat(bill.issue_date) }}</td>
                      <td>{{ dateFormat(bill.due_date) }}</td>
                      <td>{{ formatPrice(bill.total_amount) }}</td>
                    </tr>
                  </tbody>
                </table>

                <table
                  class="table table-borderless appl-view-tbl"
                  v-if="
                    settlement.installments && settlement.installments.length
                  "
                  style="max-width: 45%; margin-right: 10%; float: left;"
                >
                  <thead>
                    <tr>
                      <th colspan="6">Installments</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th>No#</th>
                      <th class="text-center">Status</th>
                      <th class="text-center">Amount</th>
                      <th class="text-center">Penalty</th>
                      <th class="text-center">Due Date</th>
                      <th class="text-center">Received Date</th>
                    </tr>
                    <tr v-for="val in settlement.installments" :key="val.id">
                      <td>{{ val.installment_no }}</td>
                      <td class="text-center">{{ val.status }}</td>
                      <td class="text-center">{{ formatPrice(val.amount) }}</td>
                      <td class="text-center">
                        {{ formatPrice(val.penalty_amount) }}
                      </td>
                      <td class="text-center">
                        {{ dateFormat(val.due_date) }}
                      </td>
                      <td class="text-center">
                        {{
                          val.receive_date
                            ? dateFormat(val.receive_date)
                            : "N/A"
                        }}
                      </td>
                    </tr>
                  </tbody>
                </table>

                <table
                  class="table table-borderless appl-view-tbl"
                  v-if="settlement.attachments && settlement.attachments.length"
                  style="max-width: 45%; float: left"
                >
                  <thead>
                    <tr>
                      <th colspan="6">Attachments</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th>File Name</th>
                      <th class="text-center">Action</th>
                    </tr>
                    <tr
                      v-for="(item, index) in settlement.attachments"
                      :key="index"
                    >
                      <td>{{ item.attachment }}</td>
                      <td class="text-center">
                        <a
                          :href="item.attachment_url"
                          download="download"
                          target="_blank"
                          title="Click to download"
                          ><i
                            class="fa fa-download fa-lg"
                            aria-hidden="true"
                          ></i
                        ></a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </CCol>
          </CRow>
        </CCard>

        <div
          class="row d-print-none"
          v-if="
            checkUsrPermissions(['BS_all', 'BS_verify']) &&
              settlement.status === 'REQUESTED'
          "
        >
          <div class="col-md-12 text-right mb-5">
            <button
              type="button"
              class="btn btn-outline-primary btn-lg mr-4 px-5"
              @click="showRejectModal = true"
            >
              Reject
            </button>
            <button
              type="button"
              class="btn btn-primary btn-lg px-5"
              @click="approve()"
            >
              Approve
            </button>
          </div>
        </div>
      </CCol>

      <!-- <CCol sm="2"></CCol> -->
      <CCol sm="12">
        <CCard>
          <h4 class="logs-title">History Logs</h4>
          <div class="table-responsive">
            <div
              v-if="settlement.logs && settlement.logs.length"
              class="log-list"
            >
              <div v-for="log in settlement.logs" :key="log.id">
                {{ log.event_description }}
                , by {{ log.user_name }}
                <p>
                  {{ log.created_at ? dateTimeFormat(log.created_at) : "N/A" }}
                </p>
              </div>
            </div>
            <div v-else>
              <h5 class="text-center">Data Not Available.</h5>
            </div>
          </div>
        </CCard>
      </CCol>
    </CRow>

    <CModal
      :closeOnBackdrop="false"
      v-if="showRejectModal"
      title="Reject Customer's Application"
      color="primary"
      class="hide-footer custom-close-button"
      :show.sync="showRejectModal"
    >
      <RejectModal
        @onSettlementRejection="dismissRejectModalModal"
      ></RejectModal>
    </CModal>
  </div>
</template>

<script>
import RejectModal from "./modals/RejectModal.vue";
import { mapGetters } from "vuex";
export default {
  name: "SettlementViewComp",
  data: () => {
    return {
      printPage: false,
      showRejectModal: false,
      months: {
        1: "January",
        2: "February",
        3: "March",
        4: "April",
        5: "May",
        6: "June",
        7: "July",
        8: "August",
        9: "September",
        10: "October",
        11: "November",
        12: "December",
      },
    };
  },
  components: {
    RejectModal,
  },
  methods: {
    dateTimeFormat(date) {
      return moment(date).format("Do MMM, YYYY h:mm a");
    },
    dateFormat(date) {
      return moment(date).format("Do MMM, YYYY");
    },
    dismissRejectModalModal() {
      this.showRejectModal = false;
    },
    approve() {
      this.$confirm({
        message: `Are you sure, want to approve the settlement?`,
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: (confirm) => {
          if (confirm) {
            this.$store
              .dispatch("BillSettlement/verifySettlement", {
                status: "APPROVED",
                id: this.$route.params.id,
              })
              .then(
                () => {
                  this.$store.dispatch("Settings/loading", false);
                  this.$toastr.s(
                    "Settlement successfuly approved.",
                    "Approved"
                  );
                },
                (error) => {
                  this.$toastr.e(error.response.data.message, "Failed!");
                  this.$store.dispatch("Settings/loading", false);
                }
              );
          }
        },
      });
    },
    print() {
      this.printPage = true;
      setTimeout(() => {
        this.printSetup();
      }, 50);
    },

    printSetup() {
      // Get HTML to print from element
      const prtHtml = document.getElementById("printArea").innerHTML;
      // Get all stylesheets HTML
      let stylesHtml = "";
      for (const node of [
        ...document.querySelectorAll('link[rel="stylesheet"], style'),
      ]) {
        stylesHtml += node.outerHTML;
      }

      // Open the print window
      const WinPrint = window.open(
        "",
        "",
        "left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0"
      );

      WinPrint.document.write(`<!DOCTYPE html>
<html>
  <head>
    <title>Bill Settlement Details</title>
    ${stylesHtml}
  </head>
  <body>
    ${prtHtml}
  </body>
</html>`);

      WinPrint.document.close();
      WinPrint.focus();
      WinPrint.addEventListener("load", () => {
        setTimeout(() => {
          WinPrint.print();
          WinPrint.close();
          this.printPage = false;
        }, 200);
      });
    },
  },
  computed: { ...mapGetters("BillSettlement", ["settlement"]) },
  mounted() {
    this.$store.dispatch(
      "BillSettlement/getSettlementInfo",
      this.$route.params.id
    );
  },
};
</script>

<style scoped>
.custom-close-button >>> button {
  color: aliceblue;
}
.hide-footer >>> footer {
  display: none;
}
.card {
  border: none;
  padding: 30px 20px;
}
tbody:before {
  content: "@";
  display: block;
  line-height: 0;
  text-indent: -99999px;
}

.card-body >>> table > tbody > tr > td {
  cursor: pointer;
}
.badge {
  padding: 5px 7px;
}
.custom-width {
  width: 100%;
}
</style>

<style lang="scss" scoped>
.avatar-container {
  width: 100%;
  padding: 4px;
  text-align: center;
  img {
    border: 1px solid #ccc;
    max-width: 100%;
  }
}
.appl-view-tbl {
  margin-bottom: 40px;
  tr {
    td {
      font-size: 14px;
      font-weight: 600;
      &:nth-child(odd) {
        color: #666;
        width: 14%;
      }
      &:nth-child(even) {
        color: #333;
        width: 19%;
      }
    }
  }
}

.logs-title {
  background: #f8f9fa;
  color: #000;
  padding: 15px;
}

.log-list {
  border: 1px solid #dadada;
  background-color: #eff0f5;
  border-radius: 4px;
  padding: 15px;
  position: relative;
  font-size: 13px;
  color: #212121;
  word-break: break-word;
  div {
    line-height: 2;
  }
  span {
    color: #9e9e9e;
  }
}

.print-btn {
  font-size: 20px;
  color: inherit;
  text-decoration: none;
  i {
    font-size: 28px;
  }
}

.customer-detail-list {
  margin-top: 5px;
  list-style-type: none;
  padding: 0;
  li {
    width: 100%;
    float: left;
    p {
      float: left;
    }
    p {
      &:first-of-type {
        width: 35%;
        max-width: 100px;
        font-weight: 600;
      }
      &:last-of-type {
        width: 65%;
      }
    }
  }
}

div {
  .row {
    .card {
      p {
        color: #000;
      }
      .row {
        p {
          color: #5e76e7;
        }
      }
    }
  }
  h4 {
    font-size: 15px;
    font-weight: bold !important;
    color: #000;
  }
  .table {
    font-size: 14px;

    thead {
      tr {
        th {
          background: #f8f9fa;
          color: #000;
        }
      }
    }
    tbody {
      font-size: 13px;
      tr {
        color: #000;
        td {
          padding: 0.75rem 0 0.75rem 0.75rem;
          button {
            font-size: 13px;
          }
        }
        // td:nth-child(1) {
        //   color: #5e76e7;
        // }
        // td:nth-child(2) {
        //   font-weight: 500 !important;
        // }
      }
    }
  }
}
</style>
